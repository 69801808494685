<template>
  <div :class="customClass" :id="elementId">
    <cz-collection-manual :button-list="buttonList"></cz-collection-manual>
    <div w1200 margin-auto style="">
      <div class="image-collection-container">
        <!-- <c-pre>{{ tableData }}</c-pre>-->
        <div class="image-wrapper" v-for="(wonderfulPicture,index) in tableData" :key="wonderfulPicture.id">
          <el-image class="image" :src="normalizeImageUrl(wonderfulPicture.src)" :preview-src-list="tableData.map(item=>normalizeImageUrl(item.src))"></el-image>
        </div>

      </div>
      <div fc style="margin-top: 30px; margin-bottom: 30px;">
        <el-pagination
            :background="background"
            :current-page="currentPage"
            :layout="layout"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :total="totalCount"
            cz-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {toMatrix}      from '@/utils/index.js'
import DataConfigMixin from '@/mixins/data-config-mixin'

export default {
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {},
  name      : 'ImageCollection',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      buttonList: [
        {
          name: 'ImageCollection',
          meta: {
            title: '精彩图片',
          },
        },
      ],
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 数据 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      tableData: [],
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 属性 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      currentPage: 1,
      pageSize   : 12,
      totalCount : 0,
      pageSizes  : [12, 24, 48, 60],
      layout     : 'total, prev, pager, next, sizes,jumper',
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 样式 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      background: false,
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    elementId() {return `ImageCollection_${this._uid}`},
    customClass() {return ['ImageCollection', this.className]},
  },
  methods : {
    getTableData() {
      const wonderfulPictures       = this.dataConfig.wonderfulPictures
      let toMatrixWonderfulPictures = toMatrix(wonderfulPictures, this.pageSize)
      this.totalCount               = wonderfulPictures.length
      this.tableData                = toMatrixWonderfulPictures[this.currentPage - 1]
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize    = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.getTableData()
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">

$spacing : 12px;

.ImageCollection {

  .image-collection-container {
    display         : flex;
    justify-content : flex-start;
    align-items     : flex-start;
    align-content   : flex-start;
    flex-wrap       : wrap;

    .image-wrapper {
      margin-right    : $spacing;
      margin-bottom   : $spacing;

      &:nth-of-type(4n) {
        margin-right : 0;
      }
      ///
      overflow        : hidden;
      width           : calc(1200px / 4 - #{$spacing} * 3);
      height          : calc(2 / 3 * 1200px / 4);
      /* box-shadow: offsetX offsetY 模糊度 阴影尺寸 颜色 外部内部阴影(outset|inset); */
      //box-shadow      : 0 0 0 1px #CCC inset;
      border          : 1px dashed rgba(16, 16, 16, 0.1);

      display         : flex;
      justify-content : center;
      align-items     : center;

      .image {
        //display    : block;
        ////width   : 100%;
        //max-width  : 100%;
        //max-height : 100%;
        width           : 100%;
        height          : 100%;

        display         : flex;
        justify-content : center;
        align-items     : center;

        ::v-deep .el-image__inner {
          max-width  : 100%;
          max-height : 100%;
          display    : block;
          width      : auto;
          height     : auto;
        }
      }
    }
  }

}
</style>
